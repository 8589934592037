import {
  ArrowDown2,
  ArrowRight,
  ArrowRight2,
  Bank,
  Chart,
  EmptyWalletTime,
  More,
  RecordCircle,
  SearchNormal1,
  TransmitSquare,
} from "iconsax-react";
import {
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import api from "../api";
import { NumericFormat } from "react-number-format";
import PreviewModal from "../component.js/PreviewModal";
import PinModal from "../component.js/PinModal";
import { ClipLoader } from "react-spinners";
import Customer from "./Customer";
import { enqueueSnackbar } from "notistack";

const MerchantDashboard = () => {
  const [timeOfDay, setTimeOfDay] = useState("");
  const [page, setPage] = useState("1");
  const [isTransferModal, setIsTransferModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [pin, setPin] = useState("");
  const [amount, setAmount] = useState("");
  const [fundPhase, setFundPhase] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isCustomer, setIsCustomer] = useState(false);
  const [seletedCustomer, setSelectedCustomer] = useState(null);
  const [transactionType, setTransactionType] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleTransferModal = () => {
    if(role === "sub-merchant") {
      enqueueSnackbar("Only Admin can transfer funds", { variant: "error" });
      return;
    }
    setIsTransferModal(true);
    setTransactionType("credit");
  };
  const handleDebitModal = () => {
    if(role === "sub-merchant") {
      enqueueSnackbar("Only Admin can debit customers wallet", { variant: "error" });
      return;
    }
    setIsTransferModal(true);
    setTransactionType("debit");
  };

  const closeTransferWallet = () => {
    setIsTransferModal(false);
    setFundPhase(1);
    ClearForm();
  };
  function ClearForm() {
    setAmount("");
    setPin("");
    setUserId("");
    setFundPhase(1);
    setTransactionType("");
  }
  let userData = localStorage.getItem("auth");

  userData = JSON.parse(userData);

  useEffect(() => {
    const getCurrentTimeOfDay = () => {
      const currentHour = new Date().getHours();
      if (currentHour >= 5 && currentHour < 12) {
        return "Morning";
      } else if (currentHour >= 12 && currentHour < 18) {
        return "Afternoon";
      } else {
        return "Evening";
      }
    };

    setTimeOfDay(getCurrentTimeOfDay());
  }, []);
  function formatDate(datetimeStr) {
    const date = Moment(datetimeStr);
    const formattedDate = date.format("MMM DD, YYYY");
    return formattedDate;
  }

  let role = userData?.user?.role;


  const fetchApplications = async (page) => {
    // //console.log("grants rsss=====>>>", res);
  };
  const ApplicationQuery = useQuery(["g", page], () => fetchApplications(page));
  const Applications = ApplicationQuery?.data?.data?.data || [];

  async function getProfile(page) {
    const response = await api.getProfile();
    return response;
  }

  const ProfileQuery = useQuery(["profile"], () => getProfile(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const profileData = ProfileQuery?.data?.data || "";

  async function getClient(page) {
    const response = await api.getClients({ params: { page } });
    return response;
  }

  const CustomerQuery = useQuery(["user"], () => getClient(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });
  let Customers = CustomerQuery?.data?.data;
  const [filteredData, setFilteredData] = useState(Customers || []);
  useEffect(() => {
    setFilteredData(CustomerQuery.data?.data);
  }, [CustomerQuery.data]);

  const handleSearch = (query) => {
    const filteredbanks = CustomerQuery.data?.data?.filter((customer) =>
      customer?.user?.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filteredbanks);
  };

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer?.user);
    setIsCustomer(false);
  };

  async function handleTransfer(e) {
    setIsLoading(true);
  

    try {
      const response = await api.creditCustomerWallet({
        merchant_id: profileData?.other_details?.merchant_details?.id,
        amount,
        user_id: seletedCustomer?.id,
      });

      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
      closeTransferWallet();
      ProfileQuery.refetch();
    } catch (e) {
      setIsLoading(false);

      enqueueSnackbar(e.message, { variant: "error" });
    }
  }

  async function handleDebit(e) {
    setIsLoading(true);
   
    try {
      const response = await api.debitCustomerWallet({
        merchant_id: profileData?.other_details?.merchant_details?.id,
        amount,
        user_id: seletedCustomer?.id,
      });

      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
      closeTransferWallet();
      ProfileQuery.refetch();
    } catch (e) {
      setIsLoading(false);

      enqueueSnackbar(e.message, { variant: "error" });
    }
  }

  async function getTransactions(page) {
    const response = await api.getTransaction({ params: { page } });
    return response;
  }

  const TransactionQuery = useQuery(["transaction"], () => getTransactions(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const TrasactionData = TransactionQuery?.data?.data || [];

  return (
    <div className="p-[16px] md:px-[24px] xl:p-[32px]">
      <div className="mb-[20px] md:mb-[28px] xl:mb-[32px]">
        <h3 className="text-[18px] md:text-[24px] font-meddium text-[#25324B]">
          Good {timeOfDay}, {userData?.user?._fields?.username}
        </h3>
        <p className="text-[14px] md:text-[16px] font-meddium text-[#7C8493]">
          {" "}
          These are your Stats
        </p>
      </div>
      <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[16px] md:gap-[20px] mt-5 ">
        <li className="rounded-lg relative z-10 bg-slate-50 overflow-hidden border-[0.8px] border-[#E4E7EC] shadow p-2 md:p-4">
          <Chart
            size="184"
            color="#E4E2DFA0"
            className="absolute bottom-0 -z-10"
          />
          <div className="flex justify-between">
            <p className="text-[14px] md:text-[16px] text-black font-meddium ">
              {" "}
              Total Balance
            </p>
            <button className="text-red px-2 py-1 rounded-md border bg-gray-200 self-end">
              <EmptyWalletTime size="18" color="#124072" />
            </button>
          </div>
          <div className="flex mt-6">
            <div className=" pr-2">
              {" "}
              <p className="text-[#000] font-semibold text-[20px] md:text-[32px]  ">
                <NumericFormat
                  value={profileData?.balance?.balance}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </p>
            </div>
          </div>
        </li>
        <button
          onClick={handleTransferModal}
          className="rounded-lg overflow-hidden border-[0.8px] border-[#E4E7EC] shadow p-2 md:p-4"
        >
          <div className="flex justify-between">
            <p className="text-[14px] md:text-[16px] text-black font-meddium ">
              {" "}
              Fund Customer Wallet
            </p>
            <div className="text-red px-2 py-1 rounded-md border bg-gray-200 self-end">
              <TransmitSquare size="18" color="#124072" />
            </div>
          </div>
          <div className="flex items-center gap-3 mt-6 justify-center">
            <img
              src="./asset/addWallet.png"
              alt="wallet"
              className="h-[54px] w-[43px]"
            />

            <div className="border-r-[0.8px] border-[#E4E7EC] pr-2">
              {" "}
              <p className="text-[#000] font-semibold text-[20px] md:text-[32px]  ">
                Add Money
              </p>
            </div>
          </div>
        </button>
        <button
          onClick={handleDebitModal}
          className="rounded-lg overflow-hidden border-[0.8px] border-[#E4E7EC] shadow p-2 md:p-4"
        >
          <div className="flex justify-between">
            <p className="text-[14px] md:text-[16px] text-black font-meddium ">
              {" "}
              Withdraw Customer Wallet
            </p>
            <div className="text-red px-2 py-1 rounded-md border bg-gray-200 self-end">
              <TransmitSquare size="18" color="#124072" />
            </div>
          </div>
          <div className="flex items-center gap-3 mt-6 justify-center">
            <img
              src="./asset/Withdraw.png"
              alt="wallet"
              className="h-[54px] w-[43px]"
            />

            <div className="border-r-[0.8px] border-[#E4E7EC] pr-2">
              {" "}
              <p className="text-[#000] font-semibold text-[20px] md:text-[32px]  ">
                Withdraw
              </p>
            </div>
          </div>
        </button>
      </ul>

      <div className="rounded-lg overflow-hidden border-[0.8px] border-[#E4E7EC] mt-5 md:mt-9">
        <div className="flex items-center justify-between bg-white p-3">
          <p className=" text-[16px] md:text-lg  text-[#000] leading-[24px] font-medium text-left ">
            Recent Transaction
          </p>
          <Link to="/transaction">
            <button className="flex items-center gap-2">
              {" "}
              <p className=" text-[14px] md:text-base  text-[#124072] leading-[24px] font-medium text-left ">
                View Transactions
              </p>
              <ArrowRight size="16" color="#124072" />
            </button>
          </Link>
        </div>
        <div class="overflow-x-auto rounded-lg">
          <table className="min-w-full mb-6 border-[0.8px] border-r-[0.8px]  border-l-[0.8px] border-[#E4E7EC] rounded-lg">
            <thead className="bg-light-gray">
              <tr className="">
                <th
                  scope="col"
                  className=" px-2 md:px-5 border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap  gap-[6px] md:gap-[12px] items-center my-0">
                    Date
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap  gap-[6px] md:gap-[12px] items-center my-0">
                    Transaction ID
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap  gap-[6px] md:gap-[12px] items-center my-0">
                    Description
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap  gap-[6px] md:gap-[12px] items-center my-0">
                    Charge
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap gap-[6px] md:gap-[12px] items-center my-0">
                    Amount
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <div>Loading...</div>}
              {isLoading && TrasactionData?.length === 0 && (
                <tr>
                  <td className="text-center" colspan="6">
                    <img
                      src="./nodata.gif"
                      className="mx-auto mt-6 h-[70px] "
                      alt=""
                    />
                    <h3 className="text-[30px] leading-[35px]  text-[#1A202C] font-extrabold mb-[6px]">
                      No Transaction Found
                    </h3>
                  </td>
                </tr>
              )}
              {TrasactionData &&
                TrasactionData?.slice(0,5)?.map((result) => (
                  <tr className="mb-2 hover:bg-light-gray">
                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      {result?.created_at}
                    </td>
                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      {result?.product_id}
                    </td>
                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      {result?.description}
                    </td>
                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      {result?.platform_charges}
                    </td>

                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      <NumericFormat
                        value={result?.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />

                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
    
    
      </div>

      <Modal
        isCentered
        isOpen={isTransferModal}
        onClose={closeTransferWallet}
        size={{ base: "xs", sm: "md", lg: "xl" }}
        style={{ borderRadius: 12 }}
        motionPreset="slideInBottom"
        className="rounded-[12px]"
      >
        <ModalOverlay />
        <ModalContent>
          {fundPhase === 1 && (
            <>
              {" "}
              <ModalHeader
                py="4"
                color="#000000"
                className="text-[18px] md:text-[20px] text-[#000000] font-medium leading-[24px] md:leading-[24px]"
              >
              {transactionType === "debit" ? "Debit" : "Credit"} A Customer Wallet
              </ModalHeader>
              <ModalCloseButton size={"sm"} />
              <Divider color="#98A2B3" />
              <ModalBody
                pt={{ base: "20px", md: "24px" }}
                px={{ base: "16px", md: "24px" }}
                pb={{ base: "30px", md: "40px" }}
                className="pt-[20px] md:pt-[24px] px-[16px] md:px-[24px] pb-[30px] md:pb-[40px]"
              >
                <div className="mb-[18px]">
                  <label className="text-[14px] text-[#667185]    mb-[8px] ">
                    Customer
                  </label>
                  <button
                    onClick={() => setIsCustomer(!isCustomer)}
                    className="w-full h-[48px] pl-[24px] pr-[8px] flex items-center justify-between  first-letter: py-[12px] text-[14px] text-[#344054]   placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none  focus:border-[#124072] "
                  >
                    <div className="flex items-center justify-between ">
                      {seletedCustomer ? (
                        <p className="text-[#272F35] font-normal font-i_normal text-[12px] leading-[15px] tracking-[0.028px] ">
                          {seletedCustomer?.name}
                        </p>
                      ) : (
                        <p className="text-[#838383] font-normal font-i_normal text-[12px] leading-[15px]  tracking-[0.028px] ">
                          {"Select a Customer"}
                        </p>
                      )}
                    </div>
                    <ArrowDown2 variant="Linear" color={"#838383"} size={14} />
                  </button>
                  {isCustomer && (
                    <div
                      initial={{ y: 10, opacity: 0.4 }}
                      animate={{
                        y: 0,
                        opacity: 1,
                        // scale: 1,
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      className="w-full h-[300px] overflow-y-auto  px-2 py-3 text-[14px] text-[#344054] border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none  focus:border-[#124072] "
                    >
                      <div className=" relative  w-full mx-auto mb-2  flex items-center">
                        <SearchNormal1
                          size="14"
                          color="#98A2B3"
                          className="absolute left-[16px]"
                        />

                        <input
                          type="text"
                          placeholder="search customer"
                          className="w-full  h-[36px] pl-[44px] py-[12px] text-[14px] text-[#344054]  bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none  focus:border-[#124072] "
                          autoComplete="on"
                          name=""
                          value={searchQuery}
                          onChange={(e) => {
                            setSearchQuery(e.target.value);
                            handleSearch(e.target.value);
                          }}
                        />
                      </div>
                      {filteredData &&
                        filteredData?.map((customer, index) => (
                          <button
                            onClick={() => handleSelectCustomer(customer)}
                            className="w-full px-[10px] py-2 rounded-[10px] flex items-center flex-row justify-between banks-center mb-2"
                            style={{
                              borderColor: "rgba(18, 3, 58, 0.10)",
                              borderWidth: 0.2,
                            }}
                          >
                            <div className="flex-item">
                              <p className="text-[#272F35] flex-1 font- font-i_medium text-[12px] leading-[15.94px]  tracking-[0.2px]  ">
                                {customer?.user?.name}
                              </p>
                            </div>

                            {seletedCustomer?.id === customer?.user?.id ? (
                              <RecordCircle
                                size="16"
                                color="#124072"
                                variant="Bold"
                              />
                            ) : (
                              <RecordCircle
                                size="16"
                                color="#DEDEDE"
                                variant="Bold"
                              />
                            )}
                          </button>
                        ))}
                    </div>
                  )}
                </div>

                <div className="my-[18px]">
                  <label className="text-[14px] text-[#667185]    mb-[8px] ">
                    Amount
                  </label>
                  <div className=" relative    flex banks-center">
                    <input
                      type="text"
                      placeholder=""
                      className="w-full h-[48px] pl-[24px] pr-[8px] py-[12px] text-[14px] text-[#344054]   placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
                      name=""
                      id="full-name"
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      autoCapitalize="off"
                      autoCorrect="off"
                      spellCheck="false"
                    />
                  </div>
                </div>
              </ModalBody>
              <Divider />
              <ModalFooter gap={"16px"}>
                <button
                  onClick={closeTransferWallet}
                  className="border-[0.2px]  border-[#98A2B3] w-[99px] text-center rounded-[8px] py-[10px] text-[14px] font-medium text-black"
                >
                  Cancel
                </button>
                <button
                  onClick={() => setFundPhase(2)}
                  className="border-[0.2px]  border-[#98A2B3] w-[99px] bg-[#124072] flex banks-center justify-center text-center rounded-[8px] py-[10px] text-[14px] font-medium text-white"
                >
                  {isLoading ? (
                    <ClipLoader color={"white"} size={20} />
                  ) : (
                    <> Send </>
                  )}
                </button>
              </ModalFooter>
            </>
          )}

          {fundPhase === 2 && (
            <>
              <PreviewModal
                isLoading={isLoading}
                userId={seletedCustomer}
                amount={amount}
                onClose={closeTransferWallet}
                handleTransfer={
                  transactionType === "debit" ? handleDebit : handleTransfer
                }
              />
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default MerchantDashboard;
