import "./App.css";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import Login from "./authScreen/Login";
import Home from "./Home";
import HomeIndex from "./screen/home";
import Notification from "./screen/Notification";

import Settings from "./screen/Settings";
import { SnackbarProvider } from "notistack";
 import Signup from "./authScreen/Signup";
 import ValidateOtp from "./authScreen/OTP";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Users from "./screen/Users";
import Products from "./screen/Products";
import Customer from "./screen/Customer";
import Department from "./screen/Department";
import Transactions from "./screen/Transactions";
import MakePayment from "./screen/CustomerScreen/MakePayment";
import ChangePin from "./screen/CustomerScreen/ChangePin";
import ChangePassword from "./screen/CustomerScreen/ChangePassword";
import Wallet from "./screen/CustomerScreen/Wallet";
import PaymentSuccess from "./screen/CustomerScreen/SuccesssPage";
import MyProfile from "./screen/CustomerScreen/Profile";
import Dashboard from "./screen/Dashboard";
import Purchases from "./screen/Purchases";
import Scanner from "./screen/Scanner";
import CustomerPurchases from "./screen/CustomerPurchases";
import Privacy from "./screen/Privacy";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: Infinity,
      },
    },
  });

  return (
    <div className="">
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route element={<Home />}>
              <Route path="/" element={<HomeIndex />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/notification" element={<Notification />} />
              <Route path="/products" element={<Products />} />
              <Route path="/user-management" element={<Users />} />
              <Route path="/customer" element={<Customer />} />
              <Route path="/department" element={<Department />} />
              <Route path="/Transaction" element={<Transactions />} />
              <Route path="/make-payment" element={<MakePayment />} />
              <Route path="/make-payment" element={<MakePayment />} />
              <Route path="/purchases" element={<Purchases />} />
              <Route path="/scanner" element={<Scanner />} />
              <Route path="/customer-purchase" element={<CustomerPurchases />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/change-pin" element={<ChangePin />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/success" element={<PaymentSuccess />} />
            <Route path="/profile" element={<MyProfile />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
          <Route path="/otp" element={<ValidateOtp />} />
          <Route path="/privacy" element={<Privacy />} />
         {/*  <Route path="/forgotpassword" element={<ForgotPassword />} /> */}
          </Routes>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
