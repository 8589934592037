import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Edit2, Eye, Maximize4, SearchNormal1, Setting3 } from "iconsax-react";
import ModalLeft from "../component.js/ModalLeft";
import Moment from "moment";
import { enqueueSnackbar } from "notistack";
import { ClipLoader } from "react-spinners";
import api from "../api";
import {
  Grid,
  Flex,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

const Users = () => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isViewModal, setIsViewModal] = useState(false);
  const [applicantDetails, setApplicantDetails] = useState(null);
  const [isEditModal, setIsEditModal] = useState(false);
  const [editId, setEditId] = useState("");
  const [searchName, setSearchName] = useState("");
  const [isCreateUser, setIsCreateUser] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
const [isSyncSubUser, setIsSyncSubUser] = useState(false);
const [subUser, setSubUser] = useState(null)
const [department, setDepartment]= useState("")



  const handleSyncSubUser = (department) => {
    setIsSyncSubUser(!isSyncSubUser);
    setSubUser(department);
  };

  const closeSyncSubUser = () => {
    setIsSyncSubUser(false);
  };

  const [formValue, setFormValue] = useState({
    name: "",
    username: "",
    phone_no: "",
    email: "",
    department: "",
  });

 
  const closeViewModal = () => {
    setIsViewModal(false);
  };
  const closeEditModal = () => {
    clearForm();
    setIsEditModal(false);
  };
  const closeCreateUser = () => {
    setIsCreateUser(false);
  };

  function formatDate(datetimeStr) {
    const date = Moment(datetimeStr);
    const formattedDate = date.format("MMM DD, YYYY");

    return formattedDate;
  }

  async function getSubmerchants(page) {
    const response = await api.getSubmerchants({ params: { page } });
    return response;
  }

  const SubUserQuery = useQuery(["users"], () => getSubmerchants(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });


  function clearForm() {
    setFormValue({
      firstname: "",
      name: "",
      username: "",
      phone_no: "",
      email: "",
      department: "",
     
    });
  }

  const handleEditModal = (item) => {
    setFormValue({
      firstname: item?.firstname,
     
    });
    setIsEditModal(true);
  };
  const handleInputChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  async function createSubmerchants(e) {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await api.createSubmerchants({
        email: formValue?.email,
        phone: formValue?.phone_no,
        name: formValue?.name,
      });

      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
      SubUserQuery.refetch()
      closeCreateUser()
      clearForm()

    } catch (e) {
      enqueueSnackbar(e.message[0], { variant: "error" });
      //////////console.error("Error updating applicant: ", e);
      setIsLoading(false);
    }
  }
  const handleCheckboxChange = (id) => {
    setSelectedId((prevSelectedId) => {
      if (prevSelectedId.includes(id)) {
        // Remove the id if it's already in the array

        return prevSelectedId.filter((itemId) => itemId !== id);
      } else {
        // Add the id if it's not in the array

        return [...prevSelectedId, id];
      }
    });
  };

  async function syncSubUser(e) {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await api.syncMerchantToDept({
        department_id: formValue?.department,
        sub_merchant_id: subUser?.sub_merchant_id,
      });

      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
      SubUserQuery.refetch();
      closeSyncSubUser()
      clearForm()
    } catch (e) {
      enqueueSnackbar(e.message[0], { variant: "error" });
      //////////console.error("Error updating applicant: ", e);
      setIsLoading(false);
    }
  }

  const Permissions = [
    { id: 1, value: "AddCustomer", name: "Add Customer" },
    { id: 2, value: "AddProduct/Services", name: "Add Product/Services" },
    { id: 3, value: "ScanBarcode", name: "Scan Barcode" },
    { id: 4, value: "DeleteCustomer", name: "Delete Customer" },
    { id: 5, value: "DeleteProduct/Services", name: "Delete Product/Services" },
    { id: 6, value: "viewTransaction", name: "view Transaction" },
    { id: 7, value: "AddMoney", name: "Add Money" },
    { id: 8, value: "EditCustomer", name: "Edit Customer" },
    { id: 9, value: "TrnaferMoney", name: "Trnafer Money" },
    { id: 10, value: "WithdrawMoney", name: "Withdraw Money" },

  ];
  async function getProducts(page) {
    const response = await api.getProducts({ params: { page } });
    return response;
  }

  const ProductQuery = useQuery(["product"], () => getProducts(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  async function getDepartments(page) {
    const response = await api.getDepartments({ params: { page } });
    return response;
  }

  const DepartmentQuery = useQuery(["dept"], () => getDepartments(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });


  return (
    <div className="p-[16px] md:px-[24px] xl:p-[32px]">
      <div className="mb-[24px] md:mb-[28px] xl:mb-[32px]">
        <h3 className="text-[18px] md:text-[24px] font-meddium text-[#25324B]">
          All Sub-Users
        </h3>
        <p className="text-[14px] md:text-[16px] font-meddium text-[#7C8493]">
          {" "}
          These are the list of Sub-Users
        </p>
      </div>

      {/* <div className="w-full justify-between flex items-center">
      {" "}
      <button
        onClick={() =>
          toggleFilter()
        }
        type="button"
        className="px-[14px] md:px-[24px] py-[10px]  md:py-[14px] text-center text-white bg-[#124072] hover:bg-[#d6a255]  flex items-center justify-center mb-[20px] md:mb-[32px]"
      >
        <p className="text-sm font-medium leading-[20px]">Filter</p>
      </button>
    </div> */}

      <div className="flex items-center justify-between mb-[20px] md:mb-[24px] ">
        <button
          onClick={() => setIsCreateUser(true)}
          type="button"
          className="px-[14px] md:px-[20px] py-[10px]  md:py-[14px] text-center text-white bg-[#124072] hover:bg-[#185392]  flex items-center justify-center mb-[20px] md:mb-[32px]"
        >
          <p className="text-sm font-medium leading-[20px]">
            Create New Sub-Users
          </p>{" "}
        </button>

        {/* <button
          // onClick={() => setIsCreateAdminModal(true)}
          type="button"
          className="px-[14px] md:px-[20px] py-[10px]  md:py-[14px] text-center text-white bg-[#124072] hover:bg-[#185392]  flex items-center justify-center mb-[20px] md:mb-[32px]"
        >
          <p className="text-sm font-medium leading-[20px]">Export</p>{" "}
        </button> */}

       
      </div>

      <div className="rounded-lg overflow-hidden border-[0.8px] border-[#E4E7EC] mt-5 md:mt-9">
        <div className="flex items-center justify-between bg-white p-3">
          <p className=" text-[16px] md:text-lg  text-[#000] leading-[24px] font-medium text-left ">
            All Sub-Users
          </p>
          <div className=" relative  mt-[16px]  flex items-center">
            <SearchNormal1 size="16" className="absolute left-[4px]" />

            <input
              type="text"
              placeholder="Search by Adminname.."
              className="w-[270px] h-[40px] pl-[36px] py-[12px] text-[14px] text-[#344054] bg-[#F9FAFB] leading-[20px]  placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
              autoComplete="on"
              name=""
              id=""
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
        </div>
        <div class="overflow-x-auto rounded-lg">
          <table className="min-w-full mb-6 border-[0.8px] border-r-[0.8px]  border-l-[0.8px] border-[#E4E7EC] rounded-lg">
            <thead className="bg-light-gray">
              <tr className="">
                <th
                  scope="col"
                  className=" px-2 md:px-5 border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex  gap-[6px] md:gap-[12px] items-center my-0">
               Name
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex  gap-[6px] md:gap-[12px] items-center my-0">
                    Email
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex  whitespace-nowrap gap-[6px] md:gap-[12px] items-center my-0">
                    Phone Number
                  </div>
                </th>
               

               
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex gap-[6px] md:gap-[12px] items-center my-0">
                    Action
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <div>Loading...</div>}
              {!isLoading && SubUserQuery?.data?.data?.length === 0 && (
                <tr>
                  <td className="text-center" colspan="4">
                    <img
                      src="./nodata.gif"
                      className="mx-auto mt-6 h-[70px] "
                      alt=""
                    />
                    <h3 className="text-[30px] leading-[35px]  text-[#1A202C] font-extrabold mb-[6px]">
                      No Sub-User Found
                    </h3>
                  </td>
                </tr>
              )}

              {SubUserQuery?.data?.data?.map((result) => (
                <tr className="mb-2 hover:bg-light-gray">
                 
                  <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                    {result?.name}
                  </td>
                  <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                    {result?.email}
                  </td>
                  <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                    {result?.phone}
                  </td>
                

                  <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                    <button
                      onClick={() => {
                       handleSyncSubUser(result)
                      }}
                    >
                      <Setting3 variant="Linear" color="#667185" size="20" />
                    </button>
                    {/* <button
                    
                    >
                      <Edit2 variant="Linear" color="#667185" size="20" />
                    </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex items-center justify-between mt-5">
        <button
          className="border-[0.8px] border-[#E4E7EC] py-2 px-3 text-center w-[90px] text-sm"
          onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </button>

        <button
          disabled={SubUserQuery?.data?.data?.length < 10}
          className="border-[0.8px] border-[#E4E7EC] py-2 px-3 text-center w-[90px] text-sm"
          onClick={() => setPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>


      <Modal
        isCentered
        isOpen={isSyncSubUser}
        onClose={closeSyncSubUser}
        size="xl"
        style={{ borderRadius: 12 }}
        motionPreset="slideInBottom"
        className="rounded-[12px]"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            py="4"
            color="#000000"
            className="text-[18px] md:text-[20px] text-[#000000] font-medium leading-[24px] md:leading-[24px]"
          >
          Add Sub-User To Department
          </ModalHeader>
          <ModalCloseButton size={"sm"} />
          <Divider color="#98A2B3" />
          <ModalBody
            pt={{ base: "20px", md: "24px" }}
            px={{ base: "16px", md: "24px" }}
            pb={{ base: "30px", md: "40px" }}
            className="pt-[20px] md:pt-[24px] px-[16px] md:px-[24px] pb-[30px] md:pb-[40px]"
          >
            <div className="mb-[18px]">
              <label className="text-[14px] text-[#667185] leading-[20px]   mb-[8px] ">
                Sub User Name
              </label>
              <div className=" relative    flex banks-center">
                <input
                  type="text"
                  placeholder=""
                  className="w-full h-[48px] pl-[24px] pr-[8px] py-[12px] text-[14px] text-[#344054] leading-[20px]  placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#185392] focus:border-[#185392] "
                  name="name"
                  id="full-name"
                  value={subUser?.name}
                  // onChange={(e) => handleInputChange(e)}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                />
              </div>
            </div>
            <div className="mb-[24px]">
              <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
                Select Department
              </label>
              <div className=" ">
                <select
                  type="text"
                  placeholder=""
                  className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
                  required
                  autoComplete="on"
                  name="department"
                  value={formValue.department}
                  onChange={(e) => handleInputChange(e)}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                >
                  <option value={""}>Select Department</option>

                  {DepartmentQuery?.data &&
                   DepartmentQuery?.data?.data?.map((department, index) => (
                      <option key={department.department_id} value={department.department_id}>
                        {department.name}
                      </option>
                    ))}
                </select>
              </div>
              </div>
           

           
          </ModalBody>
          <Divider />
          <ModalFooter gap={"16px"}>
            <button
              onClick={closeSyncSubUser}
              className="border-[0.2px]  border-[#98A2B3] w-[99px] text-center rounded-[8px] py-[12px] text-[14px] font-medium text-black"
            >
              Cancel
            </button>
            <button
              onClick={syncSubUser}
              className="border-[0.2px]  border-[#98A2B3] w-[99px] bg-[#185392] flex banks-center justify-center text-center rounded-[8px] py-[12px] text-[14px] font-medium text-white"
            >
              {isLoading ? (
                <ClipLoader color={"white"} size={20} />
              ) : (
                <> Update </>
              )}
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ModalLeft isOpen={isCreateUser} onClose={closeCreateUser}>
        <div>
          <div className="border-b border-b-[#E4E7EC] p-[16px] md:p-[20px]  md:flex justify-between items-center ">
            <div className="flex items-center gap-[16px]">
              <Maximize4 variant="Linear" color="#667185" size="16" />{" "}
              <div className="h-[32px] w-[1px] bg-[#D0D5DD]" />
              <div className="flex items-center">
                <p className="text-[#667185] text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] ">
                 Create Sub-User
                </p>
              </div>
            </div>
          </div>

          
          <form
            onSubmit={createSubmerchants}
            className=" w-full mt-[20px] px-3 md:px-6"
          >
            <div className="mb-[24px]">
              <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
                Name
              </label>
              <div className=" ">
                <input
                  type="text"
                  placeholder=""
                  className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
                  required
                  autoComplete="on"
                  name="name"
                  value={formValue.name}
                  onChange={(e) => handleInputChange(e)}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                />
              </div>
            </div>
           
           
            <div className="mb-[24px]">
              <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
                Email
              </label>
              <div className=" ">
                <input
                  type="email"
                  placeholder=""
                  className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
                  required
                  autoComplete="on"
                  name="email"
                  value={formValue.email}
                  onChange={(e) => handleInputChange(e)}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                />
              </div>
            </div>
            <div className="mb-[24px]">
              <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
                Phone
              </label>
              <div className=" ">
                <input
                  type="text"
                  placeholder=""
                  className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
                  required
                  autoComplete="on"
                  name="phone_no"
                  value={formValue.phone_no}
                  onChange={(e) => handleInputChange(e)}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                />
              </div>
            </div>

          
            {/* <div className="mb-[24px]">
              <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
                Permissions
              </label>
              <div className=" ">
                {Permissions.map((permission) => (
                  <div className="flex gap-2">
                    <input
                      type="checkbox"
                      className="text-[#124072]"
                      checked={selectedId.includes(permission.value)}
                      onChange={() => handleCheckboxChange(permission.value)}
                    />{" "}
                    <p className="text-[14px] md:text-[16px] font-meddium text-[#7C8493]">
                      {permission.name}
                    </p>
                  </div>
                ))}
              </div>
            </div> */}
            {/*  <div className="mb-[24px]">
              <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
                Confirm Password
              </label>
              <div className=" ">
                <input
                  type="text"
                  placeholder=""
                  className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
                  required
                  autoComplete="on"
                  autoFocus
                  name="confirm_password"
                  value={formValue.confirm_password}
                  onChange={(e) => 
                    handleInputChange(e)
                  }
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                />
                
             
              </div>
            </div> */}

            {/* <div className="mb-[24px]">
              <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
                Permissions
              </label>
              <div className=" ">
                {Levels.map((level) => (
                  <div className="flex gap-2">
                    <input
                      type="checkbox"
                      className="text-[#124072]"
                      checked={selectedId.includes(level.value)}
                      onChange={() => handleCheckboxChange(level.value)}
                    />{" "}
                    <p className="text-[14px] md:text-[16px] font-meddium text-[#7C8493]">
                      {level.name}
                    </p>
                  </div>
                ))}
              </div>
            </div> */}
            <button
              type="submit"
              className="w-full py-[14px] text-center text-white bg-[#124072]  flex items-center justify-center mb-[20px] md:mb-[32px]"
            >
              <p className="text-sm font-medium leading-[20px]">
                Create Sub Merchant
              </p>{" "}
              {isLoading && (
                <ClipLoader color={"white"} size={20} className="ml-2" />
              )}
            </button>
          </form>

        </div>
      </ModalLeft>

      <ModalLeft isOpen={isViewModal} onClose={closeViewModal}>
        <div>
          <div className="border-b border-b-[#E4E7EC] p-[16px] md:p-[20px]  md:flex justify-between items-center ">
            <div className="flex items-center gap-[16px]">
              <Maximize4 variant="Linear" color="#667185" size="16" />{" "}
              <div className="h-[32px] w-[1px] bg-[#D0D5DD]" />
              <div className="flex items-center">
                <p className="text-[#667185] text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] ">
                  User Details
                </p>
              </div>
            </div>
          </div>

          <div class="relative w-[60px] h-[60px] overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 mx-auto mt-3">
            <svg
              class="absolute w-[68px] h-[68px] text-gray-400 -left-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>

        
        </div>
      </ModalLeft>
    </div>
  );
};

export default Users;
