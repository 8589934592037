import React from "react";

const Privacy = () => {
  return (
    <div className="px-4 md:px-9 xl:px-14 py-6 md:py-9 bg-[#fefefe]">
      <h2 className="text-blue-500 text-[24px] md:text-[40px] font-bold mb-2">
        Privacy Policy for Payprompt
      </h2>
      <p className="text-[13px] mb-7">
        Effective Date: 14th August, 2024 Payprompt (“we,” “our,” or “us”) is
        committed to protecting your privacy. This Privacy Policy explains how
        we collect, use, share, and protect information about you when you use
        our pre-funded wallet platform (the “Service”). By using Payprompt, you
        agree to the terms of this Privacy Policy.
      </p>

      <div className="flex flex-col  gap-6 md:gap-8">
        <div>
          {" "}
          <strong>1. Information We Collect</strong>
          <p>We collect the following types of information: </p>
          {/* <ul>
    <li></li>
    
    </ul>  */}
          <ul className="text-[14px] list-disc list-inside flex flex-col gap-1 mt-4">
            <li>
              {" "}
              Personal Information Account Information: When you register for
              the Service, we collect your name, email address, phone number,
              and other contact details. Payment Information: We collect card
              details, bank transfer information, and other payment-related data
              to process your wallet funding.
            </li>
            <li>
              {" "}
              Transaction Information We record details of wallet funding and
              transactions made through the Service, including timestamps,
              amounts, and payment methods.
            </li>
            <li>
              {" "}
              Usage Information We collect information about how you interact
              with our platform, such as pages visited, features used, and
              device details (e.g., IP address, browser type, and operating
              system).
            </li>
            <li>
              {" "}
              Communication Data We may collect messages, inquiries, or feedback
              you send to us, including through customer support or surveys.{" "}
            </li>
          </ul>
        </div>
        <div>
          {" "}
          <strong> 2. How We Use Your Information:</strong>
          <p>We use your information for the following purposes: </p>
          <ul className="text-[14px] list-disc list-inside flex flex-col gap-1 mt-4">
            <li>
              {" "}
              To Provide the Service: Process transactions, manage accounts, and
              enable wallet funding.
            </li>
            <li>
              {" "}
              To Communicate: Send you transaction confirmations, account
              updates, and promotional materials (if you opt-in).
            </li>
            <li>
              To Improve the Service: Analyze usage trends to enhance the
              functionality and user experience of Payprompt.
            </li>
            <li>
              To Enhance Security: Monitor and prevent fraud, unauthorized
              access, and other security risks.
            </li>
            <li>
              To Comply with Legal Obligations: Fulfill regulatory requirements
              and respond to lawful requests from authorities.
            </li>
          </ul>
        </div>

        <div>
          {" "}
          <strong> 3. Sharing Your Information:</strong>
          <p>
            {" "}
            We do not sell your personal information. However, we may share your
            information with:{" "}
          </p>
          <ul className="text-[14px] list-disc list-inside flex flex-col gap-1 mt-4">
            <li>
              Service Providers: Third-party vendors who assist us with payment
              processing, customer support, data analytics, and other
              operational functions.
            </li>
            <li>
              Regulatory Authorities: As required by law or to comply with legal
              processes.
            </li>
            <li>
              Business Transfers: In the event of a merger, acquisition, or sale
              of assets, your information may be transferred to the new entity.
            </li>
          </ul>
        </div>

        <div>
          {" "}
          <strong> 4. Data Security:</strong>
          <p>
            We implement industry-standard security measures to protect your
            information, including:{" "}
          </p>
          <ul className="text-[14px] list-disc list-inside flex flex-col gap-1 mt-4">
            <li>Encryption: Secure transmission of sensitive data.</li>
            <li>Access Controls: Limiting access to authorized personnel.</li>
            <li>
              To Improve the Service: Analyze usage trends to enhance the
              functionality and user experience of Payprompt.
            </li>
            <li>
              Monitoring: Regular security audits and threat monitoring. Despite
              our efforts, no system can guarantee absolute security. Please
              safeguard your account credentials
            </li>
          </ul>
        </div>

        <div>
          {" "}
          <strong> 5. Your Rights :</strong>
          <p>You have the following rights regarding your information: </p>
          <ul className="text-[14px] list-disc list-inside flex flex-col gap-1 mt-4">
            <li>
              {" "}
              To Provide the Service: Process transactions, manage accounts, and
              enable wallet funding.
            </li>
            <li>
              Access and Correction: Request access to or correction of your
              personal data.
            </li>
            <li>
              Deletion: Request the deletion of your data, subject to legal and
              operational requirements.
            </li>
            <li>
              Opt-Out: Withdraw consent for promotional communications. To
              exercise these rights, contact us at info@paylodeservices.com.
            </li>
          </ul>
        </div>

        <div>
          {" "}
          <strong> 6. Cookies and Tracking Technologies:</strong>
          <p> We use cookies and similar technologies to: </p>
          <ul className="text-[14px] list-disc list-inside flex flex-col gap-1 mt-4">
            <li>Improve your user experience.</li>
            <li>Analyze platform usage.</li>
            <li>Personalize content and advertisements.</li>
            <li>
              You can manage cookie preferences through your browser settings.{" "}
            </li>
          </ul>
        </div>

        <div>
          {" "}
          <strong> 7. Retention of Information:</strong>
          <p> We retain your information as long as necessary to: </p>
          <ul className="text-[14px] list-disc list-inside flex flex-col gap-1 mt-4">
            <li>Provide the Service. </li>
            <li>Comply with legal obligations. </li>
            <li>Resolve disputes and enforce agreements. </li>
          </ul>
        </div>
        <div>
          {" "}
          <strong> 9. Contact Us</strong>
          <p>
            {" "}
            If you have questions or concerns about this Privacy Policy, contact
            us at:{" "}
          </p>
          <ul className="text-[14px] list-disc list-inside flex flex-col gap-1 mt-4">
            <li>
              <strong> Email: </strong>sales@paylodeservices.com{" "}
            </li>

            <li>
              <strong> Address:</strong> 51C, Remi Fani Kayode Avenue GRA Ikeja
            </li>
            <li>
              <strong>Phone: </strong>
              +2348083810030{" "}
            </li>
          </ul>
        </div>

        <p className="text-[11px] mt-6">
          Thank you for trusting Payprompt. We are dedicated to protecting your
          privacy and providing a secure platform.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
