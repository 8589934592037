import React, { useEffect, useRef, useState } from "react";
import { Html5Qrcode, Html5QrcodeScanner } from "html5-qrcode";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { ClipLoader } from "react-spinners";
import { enqueueSnackbar } from "notistack";
import api from "../api";

const Scanner = () => {
  const [barcodeData, setBarcodeData] = useState("");
  const [error, setError] = useState(null);
  const scannerRef = useRef(null);
  const [drugInfo, setDrugInfo] = useState(null);
  const [fdaInfo, setFdaInfo] = useState("");
  const [ndc, setNdc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [productInfo, setProductInfo] = useState("");

  const [isDrugLoading, setIsDrugLoading] = useState(false);

  function clearForm() {
    setBarcodeData("");
    setError(null);
  }

  useEffect(() => {
    handleScanner();
  }, []);

  const handleScanner = async () => {
    if (!scannerRef.current) {
      let html5QrcodeScanner = new Html5QrcodeScanner("reader", {
        fps: 10,
        qrbox: 250,
      });
      html5QrcodeScanner.render(onScanSuccess);

      // Start the scanner

      function onScanSuccess(decodedText, decodedResult) {
        setBarcodeData(decodedText);
        fetchProductDetails(decodedResult?.decodedText);
      }
      scannerRef.current = html5QrcodeScanner; // Store the scanner instance
    }
  };

  const fetchProductDetails = async (code) => {
    setIsDrugLoading(true);
    try {
      const response = await api.checkPurchaseToken(code);

      setProductInfo(response?.data);

      if (scannerRef.current) {
        scannerRef.current.clear();
        scannerRef.current = null;
      }
      setIsDrugLoading(false);
    } catch (error) {
      setIsDrugLoading(false);
      // ////console.error(error);
    }
  };

  const updateProductToken = async () => {
    setIsLoading(true);

    try {
      const response = await api.changePurchaseTokenStatus(barcodeData);

      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
      setProductInfo("")
      setBarcodeData("")
    } catch (e) {
      enqueueSnackbar("error Updating product", { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4 relative  bg-[#fefefe] space-y-6">
      <div className="mt-6">
        <div className=" space-y-">
          <div className="py-5 bg-[#124072] text-white rounded-lg">
            <h1 className="mt-[28px] text-[28px] md:text-[38px] text-center font-semibold ">
              {"QR Code Scanner"}
            </h1>
            <p className="text-center text-md text-white mt-1 ">
              {" "}
              Point your camera at a QR Code to scan
            </p>
            { !scannerRef.current && (
              <div className="flex justify-center w-full mb-2">
                <button
                  className="border px-3 py-1 text-md mx-auto rounded-lg border-white"
                  onClick={() => {
                    handleScanner();
                    clearForm();
                  }}
                >
                  Scan Again
                </button>
              </div>
            )}
            <div
              id="reader"
              style={{ width: "340px", margin: "auto" }}
              className="p-2 border rounded-lg border-gray-100"
            ></div>
          </div>

          {barcodeData && (
            <>
              <p className="text-[14px] mt-3">
                Scanned Barcode: <strong>{barcodeData}</strong>
              </p>
            </>
          )}

          {error && (
            <p className="text-red-500 text-[14px] leading-3 text-center">
              {error}
            </p>
          )}

          {isDrugLoading && (
            <div className="flex justify-center items-center gap-2">
              <ClipLoader color="#124072" size={16} />
              <p className="text-[#00B0AD] text-[14px] ">
                Fetching Drug Info...
              </p>
            </div>
          )}

          {productInfo && (
            <div className="space-y-2 mt-4">
              <h3 className="text-base font-semibold">Product Information:</h3>
              <div className="grid grid-cols-2 gap-1 text-[14px] leading-[14px]">
                <div>{productInfo?.data}</div>
              </div>
            </div>
          )}

          {productInfo && (
            <>
              <button
                onClick={updateProductToken}
                className="text-white text-[14px] w-full rounded-lg py-[6px] px-3 my-9 bg-[#124072] flex items-center gap-2 justify-center "
              >
                Approve Pickup
                {isLoading && <ClipLoader color="#fff" size={16} />}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Scanner;
