import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import CreatePin from "../../component.js/CreatePin";
import { enqueueSnackbar } from "notistack";
import api from "../../api";
import { useQuery } from "@tanstack/react-query";

const ChangePin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [oldPin, setoldPin] = useState("");
  const [isCreatePin, setIsCreatePin] = useState(false);

  // const handlePin = (e) => {
  // };
  //open pin
  const openPin = () => {
    setIsCreatePin(true);
  };

  const changePin = async (e) => {
    e.preventDefault();

    if (pin !== confirmPin) {
      enqueueSnackbar("Password does not match", { variant: "error" });
      return;
    }
    setIsLoading(true);

    try {
      const response = await api.updateUserPin({
        current_pin: oldPin,
        new_pin: pin,
        confirm_pin: confirmPin
      });

      enqueueSnackbar(response.message, { variant: "success" });

      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      enqueueSnackbar(error.message, { variant: "error" });

      setIsLoading(false);
    }
  };
  async function getProfile(page) {
    const response = await api.getProfile();
    return response;
  }



  const ProfileQuery = useQuery(["profile"], () => getProfile(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const profileData = ProfileQuery?.data?.data || "";
  return (
    <div className="p-[16px] md:px-[24px] xl:p-[32px]">
      <CreatePin isCreatePin={isCreatePin} setIsCreatePin={setIsCreatePin} />

      <div className="mb-[24px] md:mb-[28px] xl:mb-[32px] flex items-center justify-between">
        <div className="">
          <h3 className="text-[18px] md:text-[24px] font-meddium text-[#25324B]">
            Pin Management
          </h3>
          <p className="text-[14px] md:text-[16px] font-meddium text-[#7C8493]">
            Create and Update Pin
          </p>
        </div>
       {!profileData?.has_pin && (
        <button
          onClick={openPin}
          type="button"
          className="px-[14px] md:px-[20px] py-[10px]  md:py-[14px] text-center text-white bg-[#124072] hover:bg-[#124072c6]  flex items-center justify-center mb-[20px] md:mb-[32px]"
        >
          <p className="text-sm font-medium leading-[20px]">Create Pin</p>
        </button>
       )} 
      </div>

      <form onSubmit={changePin}>
        <div className="mb-[24px]">
          <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
            Current Pin
          </label>
          <div className=" ">
            <input
              type="text"
              placeholder=""
              className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
          
              
              value={oldPin}
              onChange={(e) => setoldPin(e.target.value)}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
        </div>
        <div className="mb-[24px]">
          <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
            New Pin
          </label>
          <div className=" ">
            <input
              type="text"
              placeholder=""
              className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
            
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
        </div>
        <div className="mb-[24px]">
          <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
            Confirm Pin
          </label>
          <div className=" ">
            <input
              type="text"
              placeholder=""
              className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
                
              value={confirmPin}
              onChange={(e) => setConfirmPin(e.target.value)}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
        </div>

        <button
          type="submit"
          className="w-full py-[14px] text-center text-white bg-[#124072]  flex items-center justify-center mb-[20px] md:mb-[32px]"
        >
          <p className="text-sm font-medium leading-[20px]">Change Pin</p>{" "}
          {isLoading && (
            <ClipLoader color={"white"} size={20} className="ml-2" />
          )}
        </button>
      </form>
    </div>
  );
};

export default ChangePin;
