import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import api from "../../api";
import { useQuery } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import {
  Grid,
  Flex,
  Button,
  Divider,
  Modal,
  Thead,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import PreviewModal from "../../component.js/PreviewPaymentModal";
import CreatePin from "../../component.js/CreatePin";

const MakePayment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [merchant, setMerchant] = useState(null);
  const [product, setProduct] = useState("");
  const [amount, setAmount] = useState("");
  const [pin, setPin] = useState("");
  const [note, setNote] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [id, setId] = useState("");
  const [createPin, setCreatePin] = useState(false)
  const [isConfirmLoading, setIsConfirmLoading] = useState(false)

  const openPreview = (e) => {
    e.preventDefault()
    setIsPreview(true);
  };
  const closePreview = () => {
    setIsPreview(false);
    clearForm();
  };

  function clearForm() {
    setMerchant("");
    setPin("");
    setAmount("");
    setNote("");
    setProduct("");
  }

  async function getProfile(page) {
    const response = await api.getProfile();
    return response;
  }

  async function getProduct() {
    const response = await api.getMerchantProduct(profileData?.balance?.merchant_id);
    return response;
  }

  const ProfileQuery = useQuery(["profile"], () => getProfile(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const profileData = ProfileQuery?.data?.data || "";

  const ProductQuery = useQuery(["products", profileData], () => getProduct(), {
    keepPreviousData: false,
    refetchOnWindowFocus: "always",
  });

  const ProductData = ProductQuery?.data?.data;

  async function handlePayment(e) {
    e.preventDefault();
    setIsConfirmLoading(true);

    try {
      const response = await api.makePayment({
        merchant_id: profileData?.balance?.merchant_id,
        product_id: product?.product_id,
        product_name: product?.name,
        amount: product?.price,
        pin,
        note,
      });

      enqueueSnackbar(response.message[0], { variant: "success" });
      setIsConfirmLoading(false);
      closePreview();
    } catch (e) {
      setIsConfirmLoading(false);

      enqueueSnackbar(e.message, { variant: "error" });
    }
  }


  const handleProductChange = (event) => {
    const productId = parseInt(event.target.value);
    const product = ProductData.find(p => p.product_id === productId);
    setProduct(product);
  };

 
  useEffect(()=> {
    let userData = localStorage.getItem("auth");
    let stringData = JSON.parse(userData);

    if (!stringData?.user?.has_pin) {
      setCreatePin(true)
    }
  }, [])

  return (
    <div className="p-[16px] md:px-[24px] xl:p-[32px]">
      <Modal
        isCentered
        isOpen={isPreview}
        onClose={closePreview}
        size={{ base: 'xs', sm: 'md', lg: 'xl' }}
        style={{ borderRadius: 12 }}
        motionPreset="slideInBottom"
        className="rounded-[12px]"
      >
        <ModalOverlay />
        <ModalContent>
          <PreviewModal
            isLoading={isConfirmLoading}
            handlePayment={handlePayment}
            onClose={closePreview}
            merchant={merchant}
            amount={product?.price}
            product={product?.name}
            note={note}
          />
        </ModalContent>
      </Modal>


      {!profileData?.has_pin && (<CreatePin isCreatePin={createPin} setIsCreatePin={setCreatePin} />)}
      <div className="mb-[24px] md:mb-[28px] xl:mb-[32px]">
        <h3 className="text-[18px] md:text-[24px] font-meddium text-[#25324B]">
          Make a Payment
        </h3>
        <p className="text-[14px] md:text-[16px] font-meddium text-[#7C8493]">
          {" "}
          Pay for goods or services{" "}
        </p>
      </div>

      <form onSubmit={openPreview}>
       
        <div className="mb-[24px]">

        <div className="mb-[24px]">
          <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
Merchant          </label>
          <div className=" ">
            <input
              type="text"
              placeholder=""
              className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
              autoComplete="on"
              disabled
              name="amount"
              value={profileData?.balance?.merchant_info?.business_name}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
        </div>
          <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
            Choose Product or Services
          </label>
          <div className=" ">
            <select
              type="text"
              placeholder=""
              className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
              autoComplete="on"
              name="name"
              onChange={handleProductChange}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            >
              <option value={""}>Select Product/Service</option>
              {ProductData &&
                ProductData.map((product, index) => (
                  <option key={index} value={product?.product_id}>
                    {product.name}
                    {"--"} {`${"₦"} ${product.price}`}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="mb-[24px]">
          <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
            Enter Amount
          </label>
          <div className=" ">
            <input
              type="text"
              placeholder=""
              className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
              autoComplete="on"
              disabled
              name="amount"
              value={product?.price}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
        </div>
        <div className="mb-[24px]">
          <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
            Enter Pin
          </label>
          <div className=" ">
            <input
              type="text"
              placeholder=""
              className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
              name="pin"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
        </div>
        <div className="mb-[24px]">
          <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
            Note
          </label>
          <div className=" ">
            <textarea
              type="text"
              placeholder=""
              className="w-full h-[120px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              
              name="note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
        </div>

        <button
          type="submit"
          className="w-full py-[14px] text-center text-white bg-[#124072]  flex items-center justify-center mb-[20px] md:mb-[32px]"
        >
          <p className="text-sm font-medium leading-[20px]">Make Payment</p>{" "}
          {isLoading && (
            <ClipLoader color={"white"} size={20} className="ml-2" />
          )}
        </button>
      </form>
    </div>
  );
};

export default MakePayment;
