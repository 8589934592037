import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import api from "../../api";

const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [currentPass, setCurrentPass] = useState("");
  const [passError, setPassError] = useState(false);


  function handlePassword(event) {
    let new_pass = event.target.value;
    setPassword(new_pass);

    // regular expressions to validate password
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    var specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    if (!new_pass.match(upperCase)) {
      setErrorMessage("Password should contains Uppercase letters!");
      setPassError(true);
    } else if (!new_pass.match(lowerCase)) {
      setErrorMessage("Password should contain lowercase letters!");
      setPassError(true);
    } else if (!new_pass.match(numbers)) {
      setErrorMessage("Password should contains numbers also!");
      setPassError(true);
    } else if (!new_pass.match(specialCharRegExp)) {
      setErrorMessage("Password should contains special character e.g *@#!% !");
      setPassError(true);
    } else if (new_pass.length < 6) {
      setErrorMessage("Password length should be more than 6.");
      setPassError(true);
    } else {
      setErrorMessage("Password is strong!");
      setPassError(true);
    }
    // for confirm password
  }
  const handleConfirmPassword = (event) => {
    let confirm_pass = event.target.value;
    setConfirmPassword(confirm_pass);
    if (confirm_pass !== password) {
      setConfirmPasswordError("Password does not match!");
      setPassError(true);
    } else {
      setConfirmPasswordError("Password match!");
      setPassError(false);
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      enqueueSnackbar("Password does not match", { variant: "error" });
      return;
    }
    if (passError) {
      enqueueSnackbar("Password Error", { variant: "error" });
      return;
    }
    setIsLoading(true);

    try {
      const response = await api.updatePassword({
        old_password: currentPass,
        new_password: password,
        new_password_confirmation: confirmPassword,
      });

      enqueueSnackbar(response.message, { variant: "success" });

      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      enqueueSnackbar(error.message, { variant: "error" });

      setIsLoading(false);
    }
  };
  return (
    <div className="p-[16px] md:px-[24px] xl:p-[32px]">
      <div className="mb-[24px] md:mb-[28px] xl:mb-[32px] flex items-center justify-between">
        <div className="">
          <h3 className="text-[18px] md:text-[24px] font-meddium text-[#25324B]">
            Password Management
          </h3>
          <p className="text-[14px] md:text-[16px] font-meddium text-[#7C8493]">
            Update Password
          </p>
        </div>
      </div>

      <form>
        <div className="mb-[24px]">
          <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
            Current Password
          </label>
          <div className=" ">
            <input
              type="text"
              placeholder=""
              className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
              autoComplete="on"
              name="currentPass"
              value={currentPass}
              onChange={(e) => setCurrentPass(e.target.value)}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
        </div>
        <div className="mb-[24px]">
          <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
            New Password
          </label>
          <div className=" ">
            <input
              type="text"
              placeholder=""
              className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
              autoComplete="on"
              name="name"
              value={password}
              onChange={(e) => handlePassword(e)}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
            {errorMessage && (
              <p
                className={` ${
                  errorMessage === "Password is strong!"
                    ? "text-[green]"
                    : "text-[red]"
                }  pt-1 pl-1 text-left text-xs`}
              >
                {" "}
                {errorMessage}
              </p>
            )}
          </div>

          
        </div>
        <div className="mb-[24px]">
          <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
            Confirm Password
          </label>
          <div className=" ">
            <input
              type="text"
              placeholder=""
              className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
              autoComplete="on"
              name="name"
              value={confirmPassword}
              onChange={(e) => handleConfirmPassword(e)}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
            {confirmPasswordError && (
              <p
                className={` ${
                  confirmPasswordError === "Password match!"
                    ? "text-[green]"
                    : "text-[red]"
                }  pt-1 pl-1 text-left text-xs`}
              >
                {" "}
                {confirmPasswordError}
              </p>
            )}
          </div>
        </div>

        <button
          type="button"
          onClick={changePassword}
          className="w-full py-[14px] text-center text-white bg-[#124072]  flex items-center justify-center mb-[20px] md:mb-[32px]"
        >
          <p className="text-sm font-medium leading-[20px]">Change Password</p>{" "}
          {isLoading && (
            <ClipLoader color={"white"} size={20} className="ml-2" />
          )}
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
