import React, {useEffect} from 'react';
import { CheckCircle, ArrowRight, ReceiptText, Home } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import api from '../../api';



const PaymentSuccess = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const transactionId = queryParams.get('transactionId');
    const amount = queryParams.get('amount');
    const merchantId = queryParams.get('m_id');

    let userData = localStorage.getItem("auth");
     let stringData = JSON.parse(userData);

     const requestBody =  {
      amount,
      prompt_transaction_id: transactionId,
      user_id: stringData?.user.user_id,
      merchant_id: merchantId
    }
     async function paymentCallback(endpoint) {
      try {
        const headers = {
          //Authorization: process.env.CALLBACK_KEY,
          Authorization: "",
          "Content-Type": "application/json",
          Accept: "application/json",
        };
  
        const response = await fetch(
          "https://paylode.devapi.live/v0.1/api/paylode/callback",
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestBody)
          }
        );
  
       
  
        const data = await response.json();
  
        // if (!data.success) {
        //   enqueueSnackbar("Unable to initialize payment", { variant: "error" });
        // }
        if (data.success) {
          enqueueSnackbar(data.message, { variant: "success" });

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // enqueueSnackbar("Unable to initialize payment", { variant: "error" });
      }
    }


    useEffect(() => {
        if(transactionId ){
            paymentCallback()
        }
       
    }, [amount,transactionId])
    function getFormattedCurrentDay(format = "full") {
      const date = new Date();
      const options = {
        full: { weekday: "long", year: "numeric", month: "long", day: "numeric" },
        short: { weekday: "short", month: "short", day: "numeric" },
        weekday: { weekday: "long" },
        compact: { weekday: "short" },
      };
  
      return date.toLocaleDateString("en-US", options[format]);
    }
  return (
    <div className="min-h-screen bg-gray-50 p-4">
      <div className="max-w-md w-full mx-auto bg-white rounded-xl shadow-lg px-4 py-8  md:px-8 p-8">
        {/* Success Icon */}
        <div className="flex justify-center">
          <div className="bg-green-100 rounded-full p-3">
            <CheckCircle className="w-7 h-7 md:w-12  md:h-12 text-green-500" />
          </div>
        </div>
        
        {/* Success Message */}
        <div className="text-center mt-5">
          <h1 className=" textlg md:text-2xl font-bold text-gray-800">Payment Successful!</h1>
          <p className="text-gray-600 mt-2 text-[14px] md:text-base">
            Your payment has been processed successfully. A confirmation email has been sent to your inbox.
          </p>
        </div>
        
        {/* Payment Details */}
        <div className="mt-8 bg-gray-50 rounded-lg text-[14px] md:text-[16px] p-2 md:p-4">
          <div className="flex justify-between items-center mb-4">
            <span className="text-gray-600">Amount Paid</span>
            <span className="text-gray-800 font-semibold">{amount}</span>
          </div>
          <div className="flex justify-between items-center mb-4">
            <span className="text-gray-600">Transaction ID</span>
            <span className="text-gray-800 font-semibold">{transactionId}</span>
          </div>
          <div className="flex justify-between items-center mb-4">
            <span className="text-gray-600">Date</span>
            <span className="text-gray-800 font-semibold">{getFormattedCurrentDay("short")}</span>
          </div>
         
        </div>
        
        {/* Action Buttons */}
        <div className="mt-8 space-y-4">
          {/* <button className="w-full bg-green-500 hover:bg-green-600 text-white font-medium py-3 px-4 rounded-lg flex items-center justify-center gap-2 transition-colors">
            <ReceiptText className="w-5 h-5" />
            View Receipt
          </button> */}
          
          <Link to="/" className="w-full bg-white border border-gray-200 hover:bg-gray-50 text-gray-700 font-medium py-3 px-4 rounded-lg flex items-center justify-center gap-2 transition-colors">
            <Home className="w-5 h-5" />
            Return to Homepage
          </Link>
        </div>
        
        {/* Help Link */}
        <div className="mt-6 text-center">
          <a href="#" className="text-[#124072] hover:text-[#124072] inline-flex items-center gap-1 text-sm">
            Need help? Contact support
            <ArrowRight className="w-4 h-4" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;