import React, { useState } from "react";
import Modal from "../../component.js/Modal";

import { useQuery } from "@tanstack/react-query";
import { ProfileCircle } from "iconsax-react";
import api from "../../api";
import ImageUpload from "../../component.js/UploadImage";
import { BASE_URL } from "../../utils/config";

const MyProfile = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  async function getProfile(page) {
    const response = await api.getProfile();
    return response;
  }

  const ProfileQuery = useQuery(["profile"], () => getProfile(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const profileData = ProfileQuery?.data?.data || "";

  return (
    <div>
      <div className="border-t border-b border-[#D6DDEB] p-[18px] md:p-[24px] xl:p-[32px]">
        <div className="px-[16px] md:px-[32px] xl:px-[43px] py-[24px] md:py-[42px] xl:py-[70px] flex flex-wrap items-center gap-[16px] md:gap-[24px] xl:gap-[32px] bg-[#F8F8FD] ">
          {profileData?.image ? (
            <img
              src={`
${BASE_URL}${profileData?.image}`}
              alt="profile"
              className="h-[60px] w-[60px] md:h-[80px] md:w-[80px] xl:h-[100px] xl:w-[100px] rounded-full "
            />
          ) : (
            <ProfileCircle size="60" color="#7e828b" variant="Bulk" />
          )}

          <div className="flex-1">
            <h3 className="text-[18px] md:text-[24px] xl:text-[28px] mb-1 md:mb-2 font-medium text-[#25324B]">
              {profileData?.name}
            </h3>
            <p className="text-md md:text-base xl:text-lg text-[#515B6F] ">
              {profileData?.email}
            </p>
          </div>

          <button
            onClick={() => setIsOpen(true)}
            className="w-[140px] md:w-[180px] xl:w-[200px] rounded-lg py-[10px] md:py-[7px] bg-[#25324B] text-center "
          >
            <p className="text-white text-md md:text-lg  ">Change Photo</p>
          </button>
        </div>
      </div>
      <div className="p-[18px] md:p-[24px] xl:p-[32px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[20px]">
        <ul>
          {
              profileData?.other_details?.merchant_details &&  <li className="mb-1">
              <p className="text-base md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
                Business Name
              </p>
              <p className="text-md md:text-[16px]  font-normal  text-[#515B6F]">
                {profileData?.other_details?.merchant_details?.business_name}
              </p>
            </li>
          }
            {
              profileData?.other_details?.merchant_details &&  <li className="mb-1">
              <p className="text-base md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
                Business Description
              </p>
              <p className="text-md md:text-[16px]  font-normal  text-[#515B6F]">
                {profileData?.other_details?.merchant_details?.business_type}
              </p>
            </li>
          }
       


        
          <li className="mb-1">
            <p className="text-base md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
              Full Name
            </p>
            <p className="text-md md:text-[16px]  font-normal  text-[#515B6F]">
              {profileData?.name}
            </p>
          </li>
          <li className="mb-1">
            <p className="text-base md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
              Email Address
            </p>
            <p className="text-md md:text-[16px]  font-normal  text-[#515B6F]">
              {profileData?.email}
            </p>
          </li>
          <li className="mb-1">
            <p className="text-base md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
              Phone Number
            </p>
            <p className="text-md md:text-[16px]  font-normal  text-[#515B6F]">
              {profileData?.phone}
            </p>
          </li>
          <li className="mb-1">
            <p className="text-base md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
              Notification Mail
            </p>
            <p className="text-md md:text-[16px]  font-normal  text-[#515B6F]">
              {profileData?.mail_notification}
            </p>
          </li>
        </ul>
      </div>

      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <div className="inline-block relative  p-[18px] md:p-[24px] xl:p-[32px] overflow-hidden text-left align-bottom transition-all transform bg-[white] rounded-lg   shadow-xl sm:my-8 sm:align-middle w-full min-w-[360px] md:min-w-[450px] md:max-w-[750px] ">
          <h2 className="text-[20px] md:text-[24px] xl:text-[28px] text-center font-normal leading-[35px] text-black mb-[16px] md:mb-[20px] ">
            Update Profile Image
          </h2>
          <div className="flex justify-center">
            <ImageUpload handleCloseModal={handleCloseModal} refetch={ProfileQuery.refetch()} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MyProfile;
