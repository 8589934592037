import api from "../api";

const data = {
  data: {
    userID: "LSSB-EAGLE-CREST",
    usersecret: "Admin@lssb2024",
    requestUrl: "*",
    maxrequest: "0",
  },
};


const requestOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    //Authorization: `Bearer ${token}`, // Include the token in the Authorization header
    //"x-public": "true", // Custom header for public, if needed
  },
  body: JSON.stringify(data),
};


// export const AuthLassra = async () => {

//     const response = fetch(LOGIN, requestOptions)
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }
//         return response.json();
//       })
     
     

//    const authData = await response.then((data) => {
//     // Login successful
//     const resdata = data
//     // console.log("authData", resdata);
//     // console.log("Logged in successfully", data);
//     return resdata;
//   });
// //    console.log("authData", authData);


//     return authData;

// };



export async function getProfile(page) {
  const response = await api.getProfile({ params: { page } });
  return response;
}
//
