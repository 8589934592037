//production server
// export const API_BASE_URL = 'http://94.229.79.27:55412/api/v1';

//live server
export const API_BASE_URL = 'https://paylode.devapi.live/v0.1/api/auth';
export const getApiUrl = (endpoint) => API_BASE_URL + endpoint



export const LOGIN = getApiUrl('/login');
export const SIGNUP = getApiUrl('/register');
export const OTP = getApiUrl('/verify-otp');
export const GETLASSRAUSER = getApiUrl('v1/gw/request');







