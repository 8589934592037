import { CardTick1, FolderOpen, Layer, Note1, Profile, Profile2User, Scanner } from "iconsax-react";
import React from "react";
import { BiGridAlt } from "react-icons/bi";
import { Link } from "react-router-dom";

const MerchantsSideBar = () => {

  let userData = localStorage.getItem("auth");
  let role = JSON.parse(userData);
  role = role?.user?.role;

  return (
    <div>
      {" "}
      <Link to="/dashboard" className="flex gap-2 items-center mr-2">
        <div
          className={`${
            window.location.pathname === "/dashboard" ? "block" : "hidden"
          } h-[32px] bg-[#E9EBFD]  w-[4px] `}
        ></div>
        <div
          className={`w-full   py-[12px] pl-[17px]  flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
            window.location.pathname === "/dashboard"
              ? "  text-[#002240] bg-[#E9EBFD] "
              : "text-[#fff] ml-[12px]"
          }`}
        >
          <BiGridAlt className="text-[24px] " />
          <div>Overview</div>
        </div>
      </Link>
      <Link to="/customer-purchase" className="flex gap-2 items-center mr-2">
        <div
          className={`${
            window.location.pathname === "/customer-purchase"
              ? "block"
              : "hidden"
          } h-[32px] bg-[#E9EBFD]  w-[4px] `}
        ></div>
        <div
          className={`w-full   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
            window.location.pathname === "/customer-purchase"
              ? "  text-[#002240] bg-[#E9EBFD] "
              : "text-[#fff] ml-[12px]"
          }`}
        >
          <CardTick1 size="24" />
          <div>Customer-Purchase</div>
        </div>
      </Link>
      <Link to="/transaction" className="flex gap-2 items-center mr-2">
        <div
          className={`${
            window.location.pathname === "/transaction" ||
            window.location.pathname === "/transaction"
              ? "block"
              : "hidden"
          } h-[32px] bg-[#E9EBFD]  w-[4px] `}
        ></div>
        <div
          className={`w-full   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
            window.location.pathname === "/transaction"
              ? "  text-[#002240] bg-[#E9EBFD] "
              : "text-[#fff] ml-[12px]"
          }`}
        >
          <Note1 size="24" />
          <div>Transaction</div>
        </div>
      </Link>
     {role === "merchant" &&  <Link to="/department" className="flex gap-2 items-center mr-2">
        <div
          className={`${
            window.location.pathname === "/department" ||
            window.location.pathname === "/department"
              ? "block"
              : "hidden"
          } h-[32px] bg-[#E9EBFD]  w-[4px] `}
        ></div>
        <div
          className={`w-full   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
            window.location.pathname === "/department"
              ? "  text-[#002240] bg-[#E9EBFD] "
              : "text-[#fff] ml-[12px]"
          }`}
        >
          <FolderOpen size="24" />
          <div>Department</div>
        </div>
      </Link> } 
      <Link to="/products" className="flex gap-2 items-center mr-2">
        <div
          className={`${
            window.location.pathname === "/products" ||
            window.location.pathname === "/products"
              ? "block"
              : "hidden"
          } h-[32px] bg-[#E9EBFD]  w-[4px] `}
        ></div>
        <div
          className={`w-full   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
            window.location.pathname === "/products"
              ? "  text-[#002240] bg-[#E9EBFD] "
              : "text-[#fff] ml-[12px]"
          }`}
        >
          <Layer size="24" />
          <div>Products</div>
        </div>
      </Link>
      {role === "merchant" && <> <Link to="/customer" className="flex gap-2 items-center mr-2">
        <div
          className={`${
            window.location.pathname === "/customer" ||
            window.location.pathname === "/customer"
              ? "block"
              : "hidden"
          } h-[32px] bg-[#E9EBFD]  w-[4px] `}
        ></div>
        <div
          className={`w-full   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
            window.location.pathname === "/customer"
              ? "  text-[#002240] bg-[#E9EBFD] "
              : "text-[#fff] ml-[12px]"
          }`}
        >
          <Profile2User size="24" />
          <div>Customer</div>
        </div>
      </Link>
     
      <Link to="/user-management" className="flex gap-2 items-center mr-2">
        <div
          className={`${
            window.location.pathname === "/user-management" ||
            window.location.pathname === "/user-management"
              ? "block"
              : "hidden"
          } h-[32px] bg-[#E9EBFD]  w-[4px] `}
        ></div>
        <div
          className={`w-full   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
            window.location.pathname === "/user-management"
              ? "  text-[#002240] bg-[#E9EBFD] "
              : "text-[#fff] ml-[12px]"
          }`}
        >
          <Profile size="24" />
          <div>Sub Users</div>
        </div>
      </Link></>}
      <Link to="/scanner" className="flex gap-2 items-center mr-2">
        <div
          className={`${
            window.location.pathname === "/scanner" ||
            window.location.pathname === "/scanner"
              ? "block"
              : "hidden"
          } h-[32px] bg-[#E9EBFD]  w-[4px] `}
        ></div>
        <div
          className={`w-full   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
            window.location.pathname === "/scanner"
              ? "  text-[#002240] bg-[#E9EBFD] "
              : "text-[#fff] ml-[12px]"
          }`}
        >
          <Scanner size="24" />
          <div>Scanner</div>
        </div>
      </Link>
    </div>
  );
};

export default MerchantsSideBar;
