import React, { useState } from "react";
import { Sms, CloseCircle, Eye, EyeSlash } from "iconsax-react";
import { GoLock } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import { GiPhone } from "react-icons/gi";
import { ClipLoader } from "react-spinners";
import { enqueueSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { setUserData } from "../utils/utils";
import api from "../api";

const SignUp = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [merchantId, setMerchantId] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passError, setPassError] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]); // Selected items
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [formValue, setFormValue] = useState({
    name: "",
    merchantId: "",
    email: "",
    phone: "",
  });
  const handleInputChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const deleteInput = (name) => {
    setFormValue({ ...formValue, [name]: "" });
  };

  const toggle = () => {
    setOpen(!open);
  };

  function handlePassword(event) {
    let new_pass = event.target.value;
    setPassword(new_pass);

    // regular expressions to validate password
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    var specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    if (!new_pass.match(upperCase)) {
      setErrorMessage("Password should contains Uppercase letters!");
      setPassError(true);
    } else if (!new_pass.match(lowerCase)) {
      setErrorMessage("Password should contain lowercase letters!");
      setPassError(true);
    } else if (!new_pass.match(numbers)) {
      setErrorMessage("Password should contains numbers also!");
      setPassError(true);
    } else if (!new_pass.match(specialCharRegExp)) {
      setErrorMessage("Password should contains special character e.g *@#!% !");
      setPassError(true);
    } else if (new_pass.length < 6) {
      setErrorMessage("Password length should be more than 6.");
      setPassError(true);
    } else {
      setErrorMessage("Password is strong!");
      setPassError(true);
    }
    // for confirm password
  }
  const handleConfirmPassword = (event) => {
    let confirm_pass = event.target.value;
    setConfirmPassword(confirm_pass);
    if (confirm_pass !== password) {
      setConfirmPasswordError("Password does not match!");
      setPassError(true);
    } else {
      setConfirmPasswordError("Password match!");
      setPassError(false);
    }
  };

  async function signup(e) {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await api.signUp({
        name: formValue?.name,
        email: formValue?.email,
        phone: formValue?.phone,
        merchant_ids: selectedItems,
        password: password,
      });
      enqueueSnackbar(response.message, { variant: "success" });
      navigate("/otp", { state: { email: formValue.email } });

      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
      setIsLoading(false);
    }
  }
  async function getMerchants(page) {
    const response = await api.getAllMerchants();
    return response;
  }

  const merchantsQuery = useQuery(["getMerchants"], () => getMerchants(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const allMerchantsData = merchantsQuery?.data?.data || "";

  const toggleSelectItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  // Remove selected item
  const removeItem = (item) => {
    setSelectedItems(selectedItems.filter((i) => i !== item));
  };
  return (
    <div className="bg-[#F2F2F2] h-screen w-full flex justify-center items-center ">
      <div className="bg-[#ffff] rounded-[16px] w-[90%] max-h-[70vh] overflow-auto   sm:w-[440px] md:w-[500px]  max-w-[588px] pt-[16px] md:pt-[32px]  pb-[24px] px-[16px] sm:px-[30px] md:px-[60px] xl:px-[80px] ">
        {" "}
        <img
          src="/asset/paylodeLogo.png"
          alt="logo"
          className=" h-[40px] md:h-[50px] xl:h-[60px] mx-auto mb-[20px] md:mb-[40px] xl:md-[50px]"
        />
        {/* <h2 className="text-[20px] md:text-[24px] xl:text-[28px] text-center font-bold leading-[35px] text-black mb-[8px]">
          Hi, Welcome Back!
        </h2> */}
        <p className="text-[14px] md:text-[14px] xl:text-[16px] text-center font-normal leading-[24px] text-[#667185] ">
          Sign in to your account to continue
        </p>
        <form onSubmit={signup} className="mt-[20px] md:mt-[30px]  ">
          <div className="mb-[24px]">
            <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#000000] mb-[8px]">
              Name
            </label>
            <div className=" relative    flex items-center">
              <input
                type="text"
                placeholder=""
                className="w-[100%]  h-[48px] pl-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#185392] focus:border-[#185392] "
                required
                autoComplete="on"
                name="name"
                value={formValue?.name}
                onChange={(e) =>
                  setFormValue({ ...formValue, name: e.target.value })
                }
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="mb-[24px]">
            <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#000000] mb-[8px]">
              Email
            </label>
            <div className=" relative    flex items-center">
              <Sms size="16" color="#98A2B3" className="absolute left-[16px]" />
              <CloseCircle
                size="16"
                color="#98A2B3"
                className="absolute right-[16px]"
              />

              <input
                type="email"
                placeholder="Enter your email"
                className="w-[100%]  h-[48px] pl-[44px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#185392] focus:border-[#185392] "
                required
                autoComplete="on"
                name="email"
                id="email"
                value={formValue?.email}
                onChange={(e) =>
                  setFormValue({ ...formValue, email: e.target.value })
                }
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="mb-[16px] md:mb-[20px]">
            <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#000000] mb-[8px]">
              Phone Number
            </label>
            <div className=" relative    flex items-center">
              <GiPhone
                size="16"
                color="#98A2B3"
                className="absolute left-[16px] "
              />
              <CloseCircle
                size="16"
                color="#98A2B3"
                className="absolute right-[16px] cursor-pointer"
                onClick={() => deleteInput("phone")}
              />

              <input
                type="text"
                placeholder="8083XXXXXXX"
                className="w-full  h-[48px] pl-[44px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#185392] focus:border-[#185392] "
                required
                autoComplete="on"
                name="phone"
                value={formValue.phone}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
              />
            </div>
          </div>

          <div className="mb-[24px]">
            <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#000000] mb-[8px]">
              Select Merchant
            </label>
            {/* <div className=" relative    flex items-center">
             
              <select
                type="text"
                placeholder=""
                className="w-[100%]  h-[48px] pl-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#185392] focus:border-[#185392] "
                required
                autoComplete="on"
                value={formValue?.merchantId}
                onChange={(e) => setFormValue({...formValue,  merchantId: e.target.value})}
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
              >
              
                  <option key={""} value={""}>-- Select Merchant --</option>
                  { allMerchantsData && allMerchantsData?.map((merchant) => (
                    <option key={merchant?.id} value={merchant?.id}>
                      {merchant?.name}
                    </option>
                  ))}
              
              </select>
            </div> */}
            <div className="relative w-full">
              {/* Input Field */}
              <div
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="border border-gray-300 p-2 rounde-lg rounded-lg cursor-pointer flex flex-wrap gap-1"
              >
                {selectedItems.map((item, index) => (
                  <span
                    key={index}
                    className="bg-[#185392]  text-white px-2 py-1 rounded flex items-center gap-1 text-[14px]"
                  >
                    {allMerchantsData &&
                      allMerchantsData?.map(
                        (merchant, index) =>
                          item === merchant?.merchant_id && (
                            <span key={index}>{merchant?.name}</span>
                          )
                      )}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        removeItem(item);
                      }}
                      className="text-xs ml-1 text-white hover:text-red-500"
                    >
                      ×
                    </button>
                  </span>
                ))}
                <input
                  type="text"
                  className="flex-grow border-none focus:ring-0 focus:outline-none"
                  placeholder={
                    selectedItems.length === 0 ? "Select items..." : ""
                  }
                  readOnly
                />
              </div>

              {/* Dropdown List */}
              {dropdownOpen && (
                <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-md max-h-60 overflow-y-auto z-10">
                  {allMerchantsData &&
                    allMerchantsData?.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => toggleSelectItem(item?.merchant_id)}
                        className={`p-2 cursor-pointer hover:bg-[#185392c9]  hover:text-white ${
                          selectedItems.includes(item?.merchant_id)
                            ? "bg-gray-300  text-black"
                            : ""
                        }`}
                      >
                        {item?.name}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>

          <div className="mb-[24px]">
            <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#000000] mb-[8px]">
              Password
            </label>
            <div className=" relative    flex items-center">
              <GoLock
                size="16"
                color="#98A2B3"
                className="absolute left-[16px]"
              />
              <div className="absolute right-[16px]">
                {open === false ? (
                  <Eye size="16" color="#98A2B3" onClick={toggle} />
                ) : (
                  <EyeSlash size="16" color="#98A2B3" onClick={toggle} />
                )}
              </div>
              <input
                type={open === false ? "password" : "text"}
                placeholder="Enter your password"
                className="w-full  h-[48px] pl-[44px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#185392] focus:border-[#185392] "
                required
                autoComplete="on"
                name="password"
                id="password"
                value={password}
                onChange={(e) => {
                  handlePassword(e);
                }}
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
              />
            </div>

            {errorMessage && (
              <p
                className={` ${
                  errorMessage === "Password is strong!"
                    ? "text-[green]"
                    : "text-[red]"
                }  pt-1 pl-1 text-left text-xs`}
              >
                {" "}
                {errorMessage}
              </p>
            )}
          </div>
          <div className="mb-[40px]">
            <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#000000] mb-[8px]">
              Confirm Password
            </label>
            <div className=" relative    flex items-center">
              <GoLock
                size="16"
                color="#98A2B3"
                className="absolute left-[16px]"
              />
              <div className="absolute right-[16px]">
                {open === false ? (
                  <Eye size="16" color="#98A2B3" onClick={toggle} />
                ) : (
                  <EyeSlash size="16" color="#98A2B3" onClick={toggle} />
                )}
              </div>
              <input
                type={open === false ? "password" : "text"}
                placeholder="Enter your password"
                className="w-full  h-[48px] pl-[44px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#185392] focus:border-[#185392] "
                required
                autoComplete="on"
                name="password"
                id="password"
                value={confirmPassword}
                onChange={(e) => {
                  handleConfirmPassword(e);
                }}
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
              />
            </div>
            {confirmPasswordError && (
              <p
                className={` ${
                  confirmPasswordError === "Password match!"
                    ? "text-[green]"
                    : "text-[red]"
                }  pt-1 pl-1 text-left text-xs`}
              >
                {" "}
                {confirmPasswordError}
              </p>
            )}
          </div>
          <Link to="forgotpassword">
            {" "}
            <button
              type="button"
              className="text-[14px] text-[#185392] font-medium leading-[20px] tracking-[0.2px] mb-[39px]"
            ></button>
          </Link>

          <button
            type="submit"
            className="w-full py-[14px] text-center text-white bg-[#185392] rounded-[8px] flex items-center justify-center mb-[20px] md:mb-[32px]"
          >
            <p className="text-sm font-medium leading-[20px]">Sign Up</p>
            {isLoading && <ClipLoader color={"white"} size={20} />}
          </button>

          <div className="text-[14px] leading-[20px] flex justify-center mb-[30px] md:mb-[40px]">
            <p className=" text-center">
              By clicking the Sign Up button, you agree to our{" "}
              <Link to="/privacy" className="font-medium text-[#185392] whitespace-nowrap">
                {" "}
                Privacy Policy
              </Link>{" "}
              and Terms and Conditions.{" "}
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
