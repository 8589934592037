import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";

const overrides = extendTheme({
  styles: {
    global: () => ({
      body: {
        fontFamily: '"Jost", sans-serif',
      },
    }),
  },
});
const AppTheme = extendTheme(overrides);

export default AppTheme;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={AppTheme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
