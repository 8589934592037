import React from "react";
import { useOutletContext } from "react-router-dom";
import CustomerDashboard from "./CustomerScreen/CustomerDashboard";
import MerchantDashboard from "./MerchantDashboard";

const Dashboard = () => {
  const userData = useOutletContext();

  let role = JSON.parse(userData);
  role = role?.user?.role;

  console.log("rolllee=====>>>", role)
  return (
    <div>
      {role === "merchant" || role === "sub-merchant" ? <MerchantDashboard /> : <CustomerDashboard />}
    </div>
  );
};

export default Dashboard;
